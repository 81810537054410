import React, { useState } from 'react';
import { Container, Flex, Box, Button, Text, Select } from 'theme-ui';
import Reveal from '@solid-ui-components/Reveal';
import ContentText from '@solid-ui-components/ContentText';
import envelopeIcon from '../../../../../site/content/assets/flexiblocks/icons/bx-envelope.svg';
import coin from '../../../../../site/content/assets/flexiblocks/icons/bx-dollar-circle.svg';
import userIcon from '../../../../../site/content/assets/flexiblocks/icons/bx-user.svg';
import companyIcon from '../../../../../site/content/assets/flexiblocks/icons/bx-building-house.svg';
import phoneIcon from '../../../../../site/content/assets/flexiblocks/icons/bx-phone.svg';
import { css, Spinner } from 'theme-ui';
import FormInput from '@solid-ui-components/ContentForm/FormInput';
import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi';
import { StaticImage } from 'gatsby-plugin-image';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextMask } from 'react-text-mask-hoc';
import { apiContacts } from '../../../../../site/api/api-contacts';
import './styles.css';
import { trackActionSegment, ROI_REQUESTED } from '../../../../../site/segment/segment';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const realMask = createNumberMask({
    prefix: 'R$ ',
    suffix: '',
    thousandsSeparatorSymbol: '.',
    integerLimit: 10,
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
});

const ImageComponent = ({ image, sx, ...props }) => {
    if (!image?.src) return null;

    // SVG Asset
    const isSVG = image.src.extension === 'svg';
    if (isSVG) {
        return (
            <img
                src={image.src.publicURL}
                style={{
                    width: image.width || `100%`,
                    maxWidth: image.maxWidth || `none`,
                }}
                {...props}
            />
        );
    }

    // Image Asset
    const imageData = getImage(image.src);
    if (imageData) {
        return (
            <GatsbyImage
                image={imageData}
                alt={image.alt}
                css={css({
                    verticalAlign: `middle`,
                    filter: 'grayscale(100)',
                    '&:hover': {
                        filter: 'grayscale(0)',
                        transition: 'all 0.3s ease-in-out',
                    },
                })}
            />
        );
    }

    return null;
};

const PhoneInput = props => (
    <TextMask
        icon={{
            src: phoneIcon,
            color: 'alpha',
        }}
        guide={false}
        type="TEL"
        name={'phone'}
        maxLength="20"
        id={'phone'}
        placeholder={{ text: 'Insira seu celular' }}
        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        Component={FormInput}
        value={props.value}
        onChange={props.onChange}
        touched={props.touched}
        onBlur={props.onBlur}
    />
);

const MoneyInput = props => (
    <TextMask
        icon={{
            src: coin,
            color: 'alpha',
        }}
        guide={false}
        type="TEXT"
        maxLength="60"
        name={props.name}
        id={props.id}
        placeholder={{ text: props.placeholder }}
        mask={realMask}
        Component={FormInput}
        value={props.value}
        onChange={props.onChange}
        touched={props.touched}
        onBlur={props.onBlur}
    />
);

const styles = {
    form: {
        position: `relative`,
        width: ['100%', '100%'],
        margin: '0 auto',
    },
    responseOverlay: {
        position: `absolute`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `transparent`,
        width: `full`,
        height: `105%`,
        transition: `background-color 350ms ease-in`,
        textAlign: `center`,
        zIndex: -1,
        p: 2,
        top: 0,
        left: 0,
        active: {
            zIndex: 0,
            backgroundColor: `rgba(255,255,255,0.85)`,
        },
    },
    buttonsWrapper: {
        display: `inline-flex`,
        flexWrap: `wrap`,
        justifyContent: `center`,
        '.button-group-button + .button-group-link': {
            flex: `100%`,
            ml: 0,
            mt: 3,
        },
    },
};

const text = [
    {
        text: 'CALCULE SEU ROI AGORA!',
        color: 'omegaDarker',
        variant: 'h2',
        space: 2,
    },
];

const CalcularRoiForm = () => {
    const [formValues, setFormValues] = useState({
        formState: {
            email: '',
            name: '',
            company_name: '',
            phone: '',
            contact_reason: '',
            message: '',
            number_of_trainees: '',
            trainee_remuneration: '',
            number_of_professionals: '',
            professional_remuneration: '',
        },
        isSubmitting: false,
        success: false,
        hasError: false,
        errorMessage: '',
        successMessage: '',
    });

    const { isSubmitting, success, hasError, errorMessage, successMessage } = formValues;

    const successHandler = email => {
        setFormValues({
            ...formValues,
            isSubmitting: false,
            success: true,
            hasError: false,
            successMessage: 'Obrigado, você receberá o resultado em seu e-mail!',
        });
        trackActionSegment(ROI_REQUESTED, { email: email });
    };

    const errorHandler = error => {
        setFormValues({
            ...formValues,
            isSubmitting: false,
            success: false,
            hasError: true,
            errorMessage: 'Ocorreu um erro. Por favor, tente novamente.',
        });
        console.log(error);
    };

    //Needs to format with spaces to show correctly in Slack #site channel
    const formatMessageWithSpaces = formData => {
        return `Número de estagiários: ${formData.number_of_trainees}\n        Salário médio de estagiário: ${formData.trainee_remuneration}\n        Número de advogados: ${formData.number_of_professionals}\n        Salário médio de advogado:  ${formData.professional_remuneration}`;
    };

    const submitForm = formData => {
        let contactMessage = {
            email: formData.email,
            name: formData.name,
            phone: formData.phone,
            message: formatMessageWithSpaces(formData),
            contact_reason: 'calculate_roi',
            company_name: formData.company_name,
            action: 'contact',
        };

        setFormValues({ ...formValues, isSubmitting: true });

        apiContacts
            .sendContactMessage(contactMessage)
            .then(() => successHandler(formData.email))
            .catch(errorHandler);
    };

    const renderForm = ({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
        return (
            <>
                <form css={css(styles.form)} onSubmit={handleSubmit} noValidate>
                    <Box variant={['forms.full', 'forms.row']}>
                        <Box key={`inputs-register`} variant={'forms.compact'}>
                            <Box my={2} sx={errors?.email ? { border: '2px solid #d9534f', borderRadius: '35px' } : { border: '2px solid transparent' }}>
                                <FormInput
                                    autoFocus
                                    maxLength="35"
                                    icon={{ src: envelopeIcon, color: 'alpha' }}
                                    type="TEXT"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.email}
                                    value={values.email}
                                    name={'email'}
                                    id={'email'}
                                    placeholder={{ text: 'Insira seu e-mail' }}
                                />
                            </Box>
                            <Box my={2} sx={errors?.name ? { border: '2px solid #d9534f', borderRadius: '35px' } : { border: '2px solid transparent' }}>
                                <FormInput
                                    maxLength="45"
                                    icon={{ src: userIcon, color: 'alpha' }}
                                    type="TEXT"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.name}
                                    value={values.name}
                                    name={'name'}
                                    id={'name'}
                                    placeholder={{ text: 'Nome (ex.: Ruy Barbosa)' }}
                                />
                            </Box>
                            <Box my={2} sx={errors?.company_name ? { border: '2px solid #d9534f', borderRadius: '35px' } : { border: '2px solid transparent' }}>
                                <FormInput
                                    maxLength="60"
                                    icon={{ src: companyIcon, color: 'alpha' }}
                                    type="TEXT"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.company_name}
                                    value={values.company_name}
                                    name={'company_name'}
                                    id={'company_name'}
                                    placeholder={{ text: 'Escritório/Empresa' }}
                                />
                            </Box>
                            <Box my={2} sx={errors?.phone ? { border: '2px solid #d9534f', borderRadius: '35px' } : { border: '2px solid transparent' }}>
                                <PhoneInput onChange={handleChange} onBlur={handleBlur} touched={touched.phone} value={values.phone} />
                            </Box>
                            <Flex
                                sx={{
                                    alignItems: [null, `center`],
                                    justifyContent: [null, `space-between`],
                                    flexDirection: [`column`, `row`],
                                }}>
                                <Box mr={2} my={2} sx={{ flex: 1 }}>
                                    <FormInput
                                        maxLength="60"
                                        icon={{ src: userIcon, color: 'alpha' }}
                                        type="NUMBER"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        touched={touched.number_of_trainees}
                                        value={values.number_of_trainees}
                                        name={'number_of_trainees'}
                                        id={'number_of_trainees'}
                                        placeholder={{ text: 'Nº de estagiários' }}
                                    />
                                </Box>
                                <Box my={2} sx={{ flex: 1 }}>
                                    <MoneyInput
                                        maxLength="60"
                                        icon={{ src: coin, color: 'alpha' }}
                                        type="TEXT"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        touched={touched.trainee_remuneration}
                                        value={values.trainee_remuneration}
                                        name={'trainee_remuneration'}
                                        id={'trainee_remuneration'}
                                        placeholder={'Média salarial de estagiário'}
                                    />
                                </Box>
                            </Flex>
                            <Flex
                                sx={{
                                    alignItems: [null, `center`],
                                    justifyContent: [null, `space-between`],
                                    flexDirection: [`column`, `row`],
                                }}>
                                <Box mr={2} my={2} sx={{ flex: 1 }}>
                                    <FormInput
                                        maxLength="60"
                                        icon={{ src: userIcon, color: 'alpha' }}
                                        type="NUMBER"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        touched={touched.number_of_professionals}
                                        value={values.number_of_professionals}
                                        name={'number_of_professionals'}
                                        id={'number_of_professionals'}
                                        placeholder={{ text: 'Nº de advogados' }}
                                    />
                                </Box>
                                <Box my={2} sx={{ flex: 1 }}>
                                    <MoneyInput
                                        maxLength="60"
                                        icon={{ src: coin, color: 'alpha' }}
                                        type="TEXT"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        touched={touched.professional_remuneration}
                                        value={values.professional_remuneration}
                                        name={'professional_remuneration'}
                                        id={'professional_remuneration'}
                                        placeholder={'Média salarial de advogado'}
                                    />
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                    <Box my={2} sx={{ textAlign: `center` }}>
                        <Button sx={{ width: 300 }} variant="secondary" type="submit">
                            CALCULAR!
                        </Button>
                    </Box>
                    <Box sx={styles.responseOverlay} css={(isSubmitting || success || hasError) && styles.responseOverlay.active}>
                        {isSubmitting && (
                            <Reveal effect="fadeInDown">
                                <Spinner size="64" color="alpha" />
                            </Reveal>
                        )}
                        {success && (
                            <Reveal effect="fadeInDown">
                                <Text variant="medium">{successMessage}</Text>
                                <BiCheckCircle size="64" css={css({ color: `success` })} />
                            </Reveal>
                        )}
                        {hasError && (
                            <Reveal effect="fadeInDown">
                                <Text variant="medium">{errorMessage}</Text>
                                <BiErrorCircle size="64" css={css({ color: `error` })} />
                            </Reveal>
                        )}
                    </Box>
                </form>
            </>
        );
    };

    return (
        <Container variant="cards.paper">
            <Flex
                sx={{
                    alignItems: `center`,
                    justifyContent: `center`,
                    flexDirection: [`column`, `row`],
                    padding: [null, 4],
                }}>
                <Box
                    sx={{
                        display: [`none`, null, `flex`],
                        flex: 1,
                        height: 'auto',
                        position: `relative`,
                        borderRadius: `left`,
                        overflow: `hidden`,
                        alignItems: `center`,
                        justifyContent: `center`,
                        maxWidth: [null, `220px`],
                    }}>
                    <Reveal effect={'fadeInRight'}>
                        {/* <ImageComponent image={{ src: bolsadedinheiro }} /> */}
                        <StaticImage src="./bolsa_de_dinheiro.png" alt="ROI" placeholder="ROI" layout="constrained" />
                    </Reveal>
                </Box>
                <Box sx={{ flex: 2, py: [3, 4], px: [3, 4] }}>
                    <ContentText sx={{ textAlign: 'center' }} content={text} space={3} />
                    <Formik
                        initialValues={formValues.formState}
                        enableReinitialize
                        onSubmit={submitForm}
                        render={renderForm}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Por favor, insira um e-mail válido').required('Nós precisamos do seu email para liberar seu acesso a plataforma'),
                            name: Yup.string().trim().required('Por favor, preencha seu nome'),
                            company_name: Yup.string().trim().required('Por favor, preencha o nome da empresa'),
                            phone: Yup.string().required().trim().min(14),
                            number_of_trainees: Yup.number(),
                            trainee_remuneration: Yup.string(),
                            number_of_professionals: Yup.number(),
                            professional_remuneration: Yup.string(),
                        })}
                    />
                </Box>
            </Flex>
        </Container>
    );
};

export default CalcularRoiForm;
